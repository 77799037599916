<div class="p-24">
  <h4 class="primary">{{'CONFIRM_DAILOG.TIITLE' |translate}}</h4>
  <p class="sub-text">{{'CONFIRM_DAILOG.SUB_TEXT' |translate}}</p>
  <div class="buttons">
    <button
    mat-raised-button (click)="confirmtion(false)"
    type="submit"
    color="primary"
    style="width: 100%;"
  >
    <span>{{'CONFIRM_DAILOG.STAY_BUTTON' |translate}}</span>
  </button>
  <button
  mat-raised-button (click)="confirmtion(true)"
  type="submit"
  color="accent"
  style="width: 100%;"
>
  <span>{{ 'COMMON.DISCARD' |translate}}</span>
</button>
  </div>

</div>
